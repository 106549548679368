import React, { useState } from 'react'
import { truckQuery_truck as truckType } from 'components/pages/TruckPage/types/truckQuery'
import LocationCard from 'components/LocationCard'
import Button from 'components/ui/Button'
import debounce from 'lodash/debounce'
import Link from 'next/link'

import ExternalEventCard from './ExternalEventCard'

type Props = {
  truck: truckType
}

const TruckSchedule = ({ truck }: Props) => {
  const [scheduleItems, setScheduleItems] = useState(
    truck.truckSchedule.records.slice(0, 6)
  )
  const [showViewMore, setShowViewMore] = useState(
    truck.truckSchedule.records.length > 6
  )

  const viewMoreLocations = () => {
    setScheduleItems(truck.truckSchedule.records)
    setShowViewMore(false)
  }

  const debouncedViewMoreLocations = debounce(viewMoreLocations, 100)

  return (
    <div className="pt-2 px-2 pb-12 w-full bg-gradient-to-t from-gray-50">
      <div className="mx-auto md:max-w-screen-md lg:max-w-screen-lg max-w-screen-sm">
        <h2 className="text-3xl tracking-wide my-8 font-bold text-center">
          {`${truck.name} Schedule`}
        </h2>
        <div
          className="flex flex-wrap bg-gradient-to-t from-gray-50 items-stretch justify-center"
          id="schedule"
        >
          {scheduleItems.length > 0 ? (
            scheduleItems.map((item) => {
              if (item.type === 'Location' && item.location) {
                return (
                  <LocationCard key={item.entityId} location={item.location} />
                )
              }
              if (item.type === 'ExternalEvent') {
                return (
                  <ExternalEventCard key={item.entityId} externalEvent={item} />
                )
              }
              return null
            })
          ) : (
            <div className="mx-auto text-center italic w-full text-gray-400 text-xl mt-4 mb-12">
              <p>This food truck doesn&apos;t have any public events.</p>
              {!truck.showAsArchived && (
                <p className="mt-2">
                  <Link
                    className="underline text-orange-700 hover:text-orange-600"
                    href={{
                      pathname: '/hire-food-truck',
                      query: {
                        truckId: truck.id
                      }
                    }}
                  >
                    Book this truck
                  </Link>{' '}
                  for a private event.
                </p>
              )}
            </div>
          )}
          {showViewMore && (
            <div className="mx-auto my-10">
              <Button
                color="primary"
                onClick={debouncedViewMoreLocations}
                variant="contained"
              >
                View more
              </Button>
            </div>
          )}
        </div>
      </div>
    </div>
  )
}

export default TruckSchedule
