import format from 'date-fns/format'
import parseISO from 'date-fns/parseISO'
import React from 'react'
import { truckQuery_truck_truckSchedule_records } from 'components/pages/TruckPage/types/truckQuery'
import { LocationMarkerIcon } from '@heroicons/react/outline'
import stripTimeZone from 'utils/stripTimeZone'

type Props = {
  externalEvent: truckQuery_truck_truckSchedule_records
}
const ExternalEventCard = ({ externalEvent }: Props) => (
  <div key={externalEvent.id} className="sm:w-1/3 w-full mb-6">
    <div className="h-full p-3 pt-4 flex flex-col justify-between items-center m-2 rounded-lg bg-white border-solid border border-1 border-gray-50 shadow-md box-border">
      <div className="flex flex-col items-center items-start">
        <LocationMarkerIcon className="text-orange-500 h-7 w-7 text-center sm:mr-0 mr-2" />

        <div>
          <p className="text-lg text-center font-medium sm:mt-2">
            {externalEvent.externalDescription}
          </p>

          <p className="text-sm break-words mt-3 text-center text-gray-500">
            {externalEvent.address}
          </p>
          <p className="mt-2 text-sm font-medium sm:text-center">
            {`${format(
              parseISO(stripTimeZone(externalEvent.startAt)),
              'M/d/y'
            )}, 
          ${format(
            parseISO(stripTimeZone(externalEvent.startAt)),
            'h:mm aaaa'
          )} -
          ${format(parseISO(stripTimeZone(externalEvent.endAt)), 'h:mm aaaa')}`}
          </p>
        </div>
      </div>
    </div>
  </div>
)

export default ExternalEventCard
