import React from 'react'
import ButtonLink from 'components/ui/ButtonLink'

export default function CtaSection({
  marketName,
  foodTypeName,
  href
}: {
  marketName?: string
  foodTypeName?: string | null
  href?: string | { pathname: string; query: { truckId: number } }
}) {
  return (
    <div className="bg-gradient-to-br lg:bg-gradient-to-r from-orange-600 via-orange-400 to-orange-600">
      <div className="max-w-2xl mx-auto text-center py-16 px-4 sm:py-20 sm:px-6 lg:px-8">
        <h2 className="text-3xl font-extrabold sm:text-4xl text-white">
          <span className="block">
            Hire {foodTypeName ? <span> {foodTypeName} </span> : ''}
            Food Truck{!marketName && <span> today</span>}
          </span>
          {marketName && <span>in {marketName} today</span>}
        </h2>
        <p className="mt-4 text-xl leading-6 text-white">
          Set up a regular food truck program or just a one time event
        </p>
        <div className="mt-8">
          <ButtonLink
            color="secondary"
            href={href || '/hire-food-truck'}
            size="large"
            variant="contained"
            shadow
          >
            <p className="text-black">Hire a food truck</p>
          </ButtonLink>
        </div>
      </div>
    </div>
  )
}
