import cn from 'classnames'
import Image from 'components/Image'
import { ArrowCircleRightIcon } from '@heroicons/react/solid'
import Link from 'next/link'
import React from 'react'
import {
  truckQuery_truck,
  truckQuery_truck_menus
} from 'components/pages/TruckPage/types/truckQuery'
import pathFromUrl from 'utils/pathFromUrl'
import sortBy from 'lodash/sortBy'

type Props = {
  truck: truckQuery_truck
}

const MenuPreview = ({ truck }: Props) => {
  const getDefaultMenu = ({ menus }: { menus: truckQuery_truck_menus[] }) => {
    const activeMenus = menus.filter(
      (menu) => menu.active && menu.items.length > 0
    )
    return sortBy(activeMenus, (m) => m.id)[0]
  }

  const defaultMenu = getDefaultMenu({ menus: truck.menus })
  if (!defaultMenu) return null

  const { items } = defaultMenu
  if (items.length === 0) return null

  return (
    <div className="md:px-8 px-4 mt-16 mb-10">
      <div className="mx-auto md:max-w-screen-md lg:max-w-screen-lg max-w-screen-sm">
        <h2 className="text-3xl tracking-wide my-8 font-semibold text-center">
          {`${truck.name} Menu`}
        </h2>
        <Link aria-label={`Order from `} href={pathFromUrl(truck.menuUrl)}>
          <div className="grid grid-cols-2 md:grid-cols-5 md:gap-x-6 gap-x-2 my-6">
            {items.slice(0, 4).map((item, i) => (
              <div
                key={item.id}
                className={cn(
                  'rounded-md bg-gray-100 flex flex-col cursor-pointer group shadow',
                  {
                    'hidden md:flex': i === 3
                  }
                )}
              >
                <div className="overflow-hidden">
                  <div>
                    <Image
                      alt={item.name}
                      className="rounded-t-md"
                      height={600}
                      layout="responsive"
                      sizes="25vw"
                      src={item.image.medium}
                      width={600}
                    />
                  </div>
                </div>
                <div className="flex-grow flex items-center justify-center">
                  <div className="md:text-base text-xs text-center md:pt-2 pt-1 pb-1 px-2 text-gray-700">
                    {item.name}
                  </div>
                </div>
              </div>
            ))}
            <div className="group">
              <div className="relative shadow-md hover:shadow-xl transition-all duration-200 rounded-lg box-border bg-white overflow-hidden h-full cursor-pointer">
                <div className="w-full h-full group-hover:scale-105 transition-all duration-500">
                  <Image
                    alt={`View more from ${truck.name}`}
                    layout="fill"
                    objectFit="cover"
                    sizes="(max-width: 768px) 50vw,25vw"
                    src={(items[4] ?? items[0]).image.medium}
                  />
                </div>
                <div className="bg-black bg-opacity-40 absolute inset-0 flex justify-center items-center flex-col md:flex-row">
                  <p className="text-orange-50 text-lg md:text-xl font-extrabold group-hover:text-white transition-all duration-200 text-center">
                    Full Menu
                  </p>
                  <ArrowCircleRightIcon className="md:w-10 md:h-10 w-6 h-6 text-orange-50 md:ml-3 group-hover:text-orange-200  transition-all duration-500" />
                </div>
              </div>
            </div>
          </div>
        </Link>
      </div>
    </div>
  )
}

export default MenuPreview
