import { GetStaticPathsResult, NextPage } from 'next'
import { gql, useQuery } from '@apollo/client'
import { withApollo } from 'components/withApollo'
import { useRouter } from 'next/router'
import getStaticApolloProps from 'lib/getStaticApolloProps'
import {
  truckQuery,
  truckQueryVariables
} from 'components/pages/TruckPage/types/truckQuery'
import createApolloClient from 'components/withApollo/createApolloClient'
import { truckPaths } from 'components/pages/TruckPage/types/truckPaths'
import PageLayout from 'components/pages/PageLayout'
import React, { useEffect } from 'react'
import TruckSchedule from 'components/pages/TruckPage/TruckSchedule'
import TRUCK_QUERY from 'components/pages/TruckPage/truckQuery'
import LoadingPage from 'components/pages/LoadingPage'
import NotFoundPage from 'components/pages/NotFoundPage'
import PageMeta from 'components/PageMeta'
import ErrorPage from 'components/pages/ErrorPage'
import TruckDescription from 'components/pages/TruckPage/TruckDescription'
import ActiveShift from 'components/ActiveShift'
import TruckInformationSection from 'components/HeroSection'
import useReferrals from 'hooks/useReferrals'
import TruckRatings from 'components/pages/TruckPage/TruckRatings'
import TruckInformationSectionContent from 'components/HeroSection/TruckInformationSectionContent'
import pathFromUrl from 'utils/pathFromUrl'
import LinksSection from 'components/HeroSection/LinksSection'
import MenuPreview from 'components/pages/TruckPage/MenuPreview'
import cn from 'classnames'
import { TruckIcon } from '@heroicons/react/solid'
import format from 'date-fns/format'
import parseISO from 'date-fns/parseISO'
import TruckCard from 'components/TruckCard'
import stripTimeZone from 'utils/stripTimeZone'
import { useRedirect } from 'components/RedirectProvider'
import TruckMenus from 'components/TruckMenus'
import CtaSection from 'components/pages/CateringFoodTypePage/CtaSection'

type Params = {
  seoName: string
  showInactive?: string
}

type Props = {}

const TRUCK_PATHS_QUERY = gql`
  query truckPaths {
    customerPaths {
      truckPaths
    }
  }
`

function TruckPage() {
  const router = useRouter()
  const { seoName } = router.query as Params
  const { redirect } = useRedirect()
  const { data, loading, error } = useQuery<truckQuery, truckQueryVariables>(
    TRUCK_QUERY,
    {
      variables: {
        seoName
      },
      fetchPolicy: 'cache-and-network',
      nextFetchPolicy: 'cache-first'
    }
  )
  useReferrals()
  const truck = data?.truck

  useEffect(() => {
    if (!truck?.slug) return

    if (truck.slug !== seoName) {
      router.replace(pathFromUrl(truck.scheduleUrl))
    }
  }, [truck, router, seoName])

  if (loading && !truck) return <LoadingPage />
  const notFoundError =
    error &&
    error.message.includes(
      'Cannot return null for non-nullable field Query.truck'
    )
  if (error && !notFoundError) return <ErrorPage message={error.message} />
  const isActive = truck?.active && truck?.restaurant?.active

  if (truck?.redirectToTruck) {
    return redirect({
      destination: truck.redirectToTruck.schedulePath,
      permanent: true
    })
  }
  if (!truck || notFoundError) {
    return <NotFoundPage search={seoName} />
  }
  if (!isActive && !truck.showAsArchived) {
    return <NotFoundPage search={seoName} />
  }

  const heroLinks = {
    twitter: truck.twitter,
    facebook: truck.facebook,
    instagram: truck.instagram,
    tiktok: truck.tiktok,
    website: truck.website
  }

  const otherTrucks = truck.restaurant!.trucks!.filter(
    (t) =>
      ((truck.restaurant.active && t.active) || t.showAsArchived) &&
      t.id !== truck.id
  )
  return (
    <PageLayout highlightCartItems={false} fullWidth staticPage>
      <PageMeta
        canonicalPath={pathFromUrl(truck.scheduleUrl)}
        description={`${truck.name}'s schedule`}
        image={truck.foodImage ? truck.foodImage.medium : truck.image.medium}
        ldJson={data?.structuredData?.truckPage}
        title={`Best Food Trucks | ${truck.name}`}
        url={truck.scheduleUrl}
      />

      <div className="w-full">
        <TruckInformationSection
          content={
            <TruckInformationSectionContent
              actions={[
                'bookTruck',
                'viewMenu',
                'scrollToSchedule',
                'socialMedia',
                'market'
              ]}
              heroLinks={heroLinks}
              truck={truck}
            />
          }
          imageSrc={truck.image.original!}
          links={<LinksSection socialLinks={heroLinks} />}
          size="small"
        />
        {truck.currentOrNextLocation && (
          <ActiveShift
            hideLocationInformation={false}
            itemSource="web_truck"
            locationId={truck.currentOrNextLocation.id}
            hideOrdersProgress
          />
        )}
        {!truck.showAsArchived && (
          <>
            {!truck.currentOrNextLocation &&
              truck.truckSchedule.records.length === 0 && (
                <MenuPreview truck={truck} />
              )}
            {truck.truckSchedule.records.length > 0 && (
              <TruckSchedule truck={truck} />
            )}
            {!truck.currentOrNextLocation &&
              truck.truckSchedule.records.length > 0 && (
                <MenuPreview truck={truck} />
              )}
          </>
        )}

        {truck.showAsArchived && <TruckMenus truckId={truck.id} />}

        {truck.description?.html && (
          <TruckDescription
            description={truck.description?.html}
            isGray={false}
            name={truck.name}
          />
        )}
        {truck.truckSchedule.records.length === 0 && (
          <TruckSchedule truck={truck} />
        )}

        <CtaSection
          href={{
            pathname: '/hire-food-truck',
            query: {
              truckId: truck.id
            }
          }}
        />

        {truck.ratingInfo && truck.ratingInfo.ratings.length > 0 && (
          <TruckRatings ratingInfo={truck.ratingInfo} isGray />
        )}
        {truck.pastCustomerCaterings.length > 0 && (
          <div
            className={cn(
              'pt-2 pb-12 px-4 w-full bg-gradient-to-b from-white to-gray-50'
            )}
          >
            <div
              className={cn(
                'md:max-w-screen-md lg:max-w-screen-lg max-w-screen-sm mx-auto'
              )}
            >
              <h3 className="text-2xl tracking-wide my-10 font-semibold">
                Recent Catering Events
              </h3>
              <div className="grid grid-cols-2 md:grid-cols-3 grid-flow-row gap-4">
                {truck.pastCustomerCaterings.map((r) => (
                  <div
                    key={r.id}
                    className="mb-2 p-4 rounded-md border border-solid border-gray-200"
                  >
                    <div className="flex flex-row items-center mb-2">
                      <TruckIcon className="min-w-[1.5rem] w-6 h-6 text-orange-500 hidden md:inline mr-1" />
                      <h4 className="text-lg font-semibold leading-5 text-orange-600">
                        {r.title}
                      </h4>
                    </div>
                    <div className="leading-5 mb-2 text-gray-500">
                      {format(parseISO(stripTimeZone(r.date)), 'MMMM y')},{' '}
                      {r.address}
                    </div>
                    <div>
                      <em className="italic">{r.attendees}</em> attendees
                    </div>
                  </div>
                ))}
              </div>
            </div>
          </div>
        )}
        {otherTrucks.length > 0 && (
          <div className="pt-4 lg:pt-10 pb-24 bg-gradient-to-b from-white to-gray-50">
            <div className="max-w-screen-lg mx-auto flex flex-col">
              <h2 className="text-3xl tracking-wide my-8 font-semibold text-center">
                Other trucks from {truck.restaurant.name}
              </h2>
              <div className="md:-mx-4 flex flex-wrap box-border">
                {otherTrucks.map((t) => (
                  <div key={t.id} className="md:w-1/2 w-full">
                    <TruckCard
                      foodTypes={t.menus[0]?.foodTypes ?? []}
                      image={
                        t.foodImage?.present
                          ? t.foodImage!.medium
                          : t.image.medium
                      }
                      market={t.market.name}
                      name={t.name}
                      truckPath={pathFromUrl(t.scheduleUrl)}
                    />
                  </div>
                ))}
              </div>
            </div>
          </div>
        )}
      </div>
    </PageLayout>
  )
}

export default withApollo({ ssr: false })(TruckPage as NextPage)

export async function getStaticPaths(): Promise<GetStaticPathsResult> {
  const client = createApolloClient({}, null)

  const { data } = await client.query<truckPaths>({
    query: TRUCK_PATHS_QUERY
  })

  const paths = data.customerPaths.truckPaths.map((truckPath) => ({
    params: { seoName: truckPath }
  }))

  return {
    paths,
    fallback: 'blocking'
  }
}

export const getStaticProps = getStaticApolloProps<Props, Params>(TruckPage, {
  revalidate: 60 * 5 // 5 minutes
})
