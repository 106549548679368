import { gql } from '@apollo/client'
import { scheduleLocationFragment } from 'components/LocationCard'
import { truckRatingsFragment } from 'components/pages/TruckPage/TruckRatings'

export default gql`
  ${scheduleLocationFragment}
  ${truckRatingsFragment}
  query truckQuery($seoName: String!) {
    truck(seoName: $seoName) {
      id
      active
      showAsArchived
      preferredVendor
      name
      slug
      scheduleUrl
      menuUrl
      facebook
      instagram
      website
      twitter
      tiktok
      preferredVendorBadge
      description {
        html
      }
      foodImage {
        medium
      }
      ratingInfo {
        averageRating
        ratingsCount
        ratings {
          id
        }
      }
      market {
        id
        customerPath
        customerTrucksListPath
        name
      }
      pastCustomerCaterings {
        id
        title
        date
        address
        attendees
      }
      redirectToTruck {
        id
        schedulePath
      }
      menus {
        id
        name
        active
        catering
        public
        foodTypes {
          id
          name
        }
        manualOrder
        items(
          sort: [{ field: auto, direction: asc }]
          page: { limit: 5 }
          filter: { active: true }
        ) {
          id
          name
          active
          manualPosition
          image {
            medium
          }
        }
      }
      image {
        medium
        original
      }
      description {
        html
      }
      restaurant {
        id
        active
        name
        logo {
          thumb
          medium
          original
        }
        trucks {
          id
          name
          scheduleUrl
          active
          showAsArchived
          market {
            id
            name
          }
          menus {
            id
            foodTypes {
              id
              name
            }
          }
          image {
            medium
            original
          }
          foodImage {
            present
            medium
          }
        }
      }
      currentOrNextLocation {
        id
      }
      primaryUpsellTypes {
        id
        name
      }
      ...truckRatingsFragment
      truckSchedule {
        total
        records {
          id
          type
          entityId
          address
          startAt
          endAt
          externalDescription
          location {
            ...scheduleLocationFragment
          }
        }
      }
    }
    structuredData {
      truckPage(seoName: $seoName)
    }
  }
`
